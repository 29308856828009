header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
header .right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 28px;
  gap: 12px;
}
header .left {
  display: flex;
  position: absolute;
  width: 110px;
  left: 0px;
}
