.page-title {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
}

.page-title .action {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-self: flex-end;
}

.pages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.page-title .left {
  display: flex;
  gap: 16px;
  align-items: center;
}
