.box-display {
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  overflow: visible;
  height: 100%;
  padding: 8px;
}

.box-display .action svg {
  width: 28px;
  height: 28px;
  overflow: visible;
  aspect-ratio: 1;
}
.box-display .loading-indicator {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 240px;
  height: 300px;
  background-color: #fff;
  box-shadow: 2px 2px 4px #0003;
  border-radius: 8px;
}

.box-display .box-content {
  gap: 12px;
  display: flex;
  width: 100%;
  /* flex-direction: row; */
  height: calc(100vh - 130px);
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
}
.box-display .boxes {
  min-width: 240px;
  width: fit-content;
  height: fit-content;
  /* height: 300px; */
  background-color: #fff;
  box-shadow: 2px 2px 4px #0003;
  border-radius: 8px;
  padding: 12px;
}
.box-display .boxes p {
  line-height: 1.7rem;
}

.box-display .action {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 8px 12px 0 0;
}
.box-display .img-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.box-display .box-content img {
  height: 120px;
  margin-bottom: 12px;
  border-radius: 8px;
}

.box-display .add-new-lottery {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 2px 2px 4px #0003;
  border-radius: 8px;
  gap: 8px;
}
.add-new-lottery svg {
  width: 40px;
  height: 40px;
}
.loading-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.box-display .loading-image {
  border: 1px dashed var(--light);
  width: 120px;
  height: 120px;
}
