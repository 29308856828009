.list-display {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 2px 2px 4px #0003;
  border-radius: 8px;
  overflow: hidden;
  height: fit-content;
  justify-content: center;
  background-color: #fff;
}
.list-display .list-title,
.list-display .list-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 8px;
}
.list-display .list-title {
  background-color: var(--primary);
  /** enable this line if you can't scrollbar  */
  /* padding-right: 16px; */
}

.list-display .list-title p {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
}

.list-content {
  /* background-color: #fff; */
  height: fit-content;
  height: fit-content;
  overflow-y: scroll;
}
.list-display p {
  text-align: center;
  /* background-color: #999; */
  padding: 0px 6px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5rem;
}

.list-display .no,
.list-display .qty {
  width: 3rem;
}
.list-display .name,
.list-display .long.date,
.list-display .password,
.list-display .name-warp {
  width: 12rem;
}
.list-display .name-warp {
  white-space: normal;
  word-wrap: break-word;
}
.list-display .short-date {
  width: 8rem;
}
.list-display .date {
  width: 9rem;
}
.list-display .name-long,
.list-display .date-time {
  width: 18rem;
}
.list-display .name-long {
  white-space: normal;
}
.list-display .short-date-time {
  width: 13rem;
}
.list-display .price {
  width: 10rem;
}
.list-display .long-price {
  width: 12rem;
}
.list-display .list-row .price,
.list-display .list-row .long-price {
  text-align: right;
}
.list-display .long-mid {
  width: 12rem;
}
.list-display .ph-no {
  width: 11rem;
}
.list-display .address {
  width: 20rem;
  white-space: normal;
}
.list-display .action {
  width: 120px;
  display: flex;
  gap: 32px;

  justify-content: center;
}
/* .list-display .action2 {
  width: 120px;
} */
.list-display .action3 {
  width: 140px;
}
.list-display .action4 {
  width: 180px;
}

.list-display .action svg {
  width: 24px;
  height: 24px;
  overflow: visible;
  aspect-ratio: 1;
}
.list-display .loading-indicator {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

@media only screen and (max-width: 1000px) {
  .list-display .name,
  .list-display .long.date,
  .list-display .password {
    width: 10rem;
  }
  .list-display .short-date {
    width: 6.5rem;
  }
  .list-display .date {
    width: 7.5rem;
  }
  .list-display .name-long,
  .list-display .date-time {
    width: 15rem;
  }
  .list-display .name-long {
    white-space: normal;
  }
  .list-display .short-date-time {
    width: 11.5rem;
  }
  .list-display .price {
    width: 8rem;
  }
  .list-display .long-price {
    width: 10rem;
  }
  .list-display .list-row .price,
  .list-display .list-row .long-price {
    text-align: right;
  }
  .list-display .long-mid {
    width: 10rem;
  }
  .list-display .ph-no {
    width: 9rem;
  }
  .list-display .address {
    width: 18rem;
  }
  .list-display .action {
    width: 100px;
    display: flex;
    gap: 24px;
  }
  .list-display .action3 {
    width: 120px;
  }
  .list-display .action4 {
    width: 160px;
  }
}
