.report-title {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-bottom: 10px;
}

.report-title span {
  display: inline-block;
  padding: 0 6px;
}

.report-title .click {
  cursor: pointer;
}
.report-title .click:hover {
  color: var(--primary);
  text-decoration: underline;
}

.report-title .symbol {
  font-size: 1.5rem;
  font-weight: 500;
}

.report-title .back-btn:hover,
.ticket-title .back-btn:hover {
  fill: var(--primary);
  cursor: pointer;
}
