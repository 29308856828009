.ticketVoucher {
  width: 1100px;
  height: 600px;
  background-image: linear-gradient(-30deg, #000928, #2b3d7f, #121a37);
  padding: 0 32px;
  position: relative;
}

.ticketVoucher * {
  color: #fff;
}
.ticketVoucher h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.ticketVoucher .title {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
}
.ticketVoucher .logo {
  width: 200px;
  height: 120px;
  margin-top: 48px;
  object-fit: cover;
}
/* .ticketVoucher .time {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40px;
} */
.ticketVoucher .time {
  width: fit-content;
  border-bottom: 3px solid #fff5bfdc;
  height: 50px;
}
.ticketVoucher .ticket {
  margin-right: 32px;
  border: 2px solid #ffea74dc;
  width: 240px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-top: 28px;
}
.ticketVoucher .ticket h1 {
  font-size: 20px;
  color: #ccc;
}
.ticketVoucher .ticket span {
  font-size: 28px;
  color: #ffd700;
}
.ticketVoucher .body {
  width: 100%;
  height: 304px;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: center;
}
.ticketVoucher .cus-info {
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ticketVoucher .cus-info > div {
  width: fit-content;
  display: flex;
  gap: 24px;
}
.ticketVoucher .cus-info p {
  font-size: 24px;
}
.ticketVoucher .cus-info p:nth-child(1) {
  width: 120px;
}
.ticketVoucher .cus-info p:nth-child(3) {
  width: calc(100% - 150px);
}

.ticketVoucher .pic-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ticketVoucher .pic-container img {
  width: 300px;
  height: 200px;
  object-fit: contain;
}
.ticketVoucher .pic-container p {
  font-size: 32px;
  padding: 8px 20px 2px 20px;
  border: 2px solid #ffea74dc;
  color: #ffd700;
  border-radius: 8px;
}

.ticketVoucher .address {
  max-width: 320px;
}
.ticketVoucher .ph-no {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 20px;
  margin: 20px 0;
}
.ticketVoucher .date {
  width: 100%;
  font-size: 20px;
}
.ticketVoucher .date p {
  color: #ff4141;
  letter-spacing: 0.25rem;
}
