nav {
  width: 110px;
  height: calc(100vh - 60px);
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
  position: fixed;
  top: 60px;
  left: -110px;
  transition: 0.4s;
}
nav.extended {
  left: 0;
}

nav ul {
  height: calc(100% - 40px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

nav ul::-webkit-scrollbar {
  display: none;
}

nav li {
  display: flex;
  height: 80px;
  width: 80px;
  border-radius: 8px;
  margin-bottom: 8px;
}

nav a {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  color: #000;
  text-decoration: none;
}
nav li p {
  font-size: 13px;
  font-weight: 500;
}

nav li.active {
  border: 1px solid #000;
  background-color: #e6e9f4;
}

nav li svg {
  width: 32px;
  height: 32px;
}
nav li svg .nav-color-base {
  fill: var(--nav-base);
}
nav li svg .nav-color-primary {
  fill: var(--nav-inactive);
}
nav li.active svg .nav-color-primary {
  fill: var(--nav-active);
}
nav li svg .nav-color-base-2 {
  fill: var(--nav-base);
}
nav li svg .nav-color-primary-2 {
  fill: var(--nav-inactive);
}
nav li.active svg .nav-color-base-2 {
  fill: var(--nav-active);
  opacity: 1;
}

nav .logout {
  padding: 8px;
  position: absolute;
  bottom: 12px;
}
nav .logout p {
  font-size: 14px;
}

nav .logout svg {
  width: 24px;
  height: 24px;
}
