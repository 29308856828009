.tickets {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ticket-title {
  width: 100%;
  height: 180px;
  display: flex;
  padding-left: 12px;
  gap: 20px;
  border-bottom: 1px solid #000;
  padding-bottom: 12px;
}
.ticket-title .inner {
  width: calc(100% - 40px);
  display: flex;
  /* align-items: center; */
  gap: 16px;
}
.ticket-info {
  width: calc(50% - 16px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  padding: 20px;
  box-shadow: 3px 3px 4px #0003;
  border-radius: 8px;
}
.ticket-info .left {
  min-width: 200px;
}
.ticket-info p {
  line-height: 1.5rem;
}
.ticket-info img {
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
.tickets .ticket-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.color-box-container {
  width: calc(100% - 8px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  box-shadow: 3px 3px 4px #0003;
  height: 100px;
  border-radius: 8px;
}
.color-box {
  display: flex;
  align-items: center;
  gap: 12px;
}
.color-box > div {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.color-box .text {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 8px;
}

.tickets .tickerSearch {
  width: 200px;
}
.ticket-title .action-container {
  width: 100%;
  display: flex;
  gap: 20px;
}
/* .ticket-title .action-container .search {
  display: flex;
  gap: 16px;
} */

.ticket-body {
  width: 100%;
  height: calc(100vh - 254px);

  padding: 12px;
  /* background-color: #fff; */
}
.ticket-boxes {
  display: flex;
  gap: 12px;
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  margin-top: 12px;
  align-content: flex-start;
}
.ticket-boxes::-webkit-scrollbar {
  width: 16px;
}
.ticket-boxes p {
  min-width: 60px;
  text-align: center;
  width: fit-content;
  height: fit-content;
  padding: 12px;
  border-radius: 4px;
  color: #fff;
  font-variant-numeric: tabular-nums;
}

.export-ticket-boxes {
  display: flex;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  overflow: hidden;
}
.export-ticket-boxes p {
  width: fit-content;
  height: fit-content;
  padding: 4px 6px;
  border-radius: 4px;
  color: #fff;
  font-variant-numeric: tabular-nums;
}

.outline {
  color: #000 !important;
  background-color: #fff;
  border: 1px solid #000;
}

.export-container .warning,
.export-container .danger {
  border: 1px solid #fff;
}
.export-container .bgImg {
  width: 41%;
  object-fit: cover;
  opacity: 0.3;
  filter: blur(2px);
}
.watermark {
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  gap: 120px 0%;
  flex-wrap: wrap;
  justify-content: center;
}

.watermark p {
  color: #999;
  font-size: 60px;
  opacity: 0.7;
  transform: rotate(35deg);
}
.multiTicketContainer {
  display: flex;
  gap: 12px;
  width: 360px;
  flex-wrap: wrap;
  justify-content: center;
}

.multiTicketCells {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 10px;
  border: 2px solid var(--primary);
}

.multiTicketName {
  width: 96%;
  text-align: right;
}
.multiTicketCells.soldOut {
  border-color: var(--danger);
}
.multiTicketCells.sus {
  border-color: var(--success);
}
.multiTicketName span {
  font-weight: 500;
  font-size: 17px;
}
