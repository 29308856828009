/* wifiIcon.css */

#ping-status {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 2px;
}

/* #wifi-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
} */

#wifi-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
}
